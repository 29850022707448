import React, { useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import './faq.scss'

import { API_ENDPOINTS } from '../../core/constants/apiEndpoints'
let imageSrcPath= API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let ExpandArrow = imageSrcPath+'/down-arrow-red.svg'

export default function HomeFAQs() {
  const [pannel, setPannel] = useState('')

  const togglePannel = pannelName => {
    if (pannelName === pannel)
      setPannel('')
    else
      setPannel(pannelName)
  }

  return (
    <div className="sectionCustomMarginTop" >
      <div className="row">
        <div className="col-sm-12 homepagePaddingMuted">
          <div className="faq-container">
            <h2 className="font-bold text-center sectionCustomPaddingBtm mb-0" >Frequently Asked Questions</h2>
            <ExpansionPanel
              expanded={pannel === 'panel1'}
              onChange={() => togglePannel('panel1')}
              classes={{ root: 'faq-home' }}
              key={'panel1'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel1'}
              >
                How can I buy a bike from CredR?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>
                Buying used bikes from CredR is quick and easy. By visiting the online website of CredR you can witness the large range of bikes listed in order. Choose your location and set your budget this helps you to narrow down your buying options for used two wheelers. Check out the list of bikes available to fit in your budget and the maximum you can stretch to get a good bike, CredR will help you out.                  </p>
                <p>
                Fill in the details accurately; CredR executive will get in touch with you shortly. If you wish to physically see the second hand two wheeler then visit the nearby showroom using the  
                {/* <a href='https://www.credr.com/showroom-locator'><b>showroom locator</b></a> on the website. You can book a test drive at home by just paying 399 INR. Understand the positive highlights of the used bikes whether it fits your comfortability, feels good and meets your expectations or not! Once you finalize choosing the preferred used two wheeler, further CredR executive will guide you to process your buying parameters.                  </p> */}
                <a href='https://www.credr.com'><b>showroom locator</b></a> on the website. You can book a test drive at home by just paying 399 INR. Understand the positive highlights of the used bikes whether it fits your comfortability, feels good and meets your expectations or not! Once you finalize choosing the preferred used two wheeler, further CredR executive will guide you to process your buying parameters.                  </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={pannel === 'panel2'}
              onChange={() => togglePannel('panel2')}
              classes={{ root: 'faq-home' }}
              key={'panel2'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel2'}
              >
                How to sell my bike to CredR?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>CredR has made your bike selling process hassle-free. By visiting the CredR website click on the option ‘Sell’. You can thereby choose location/city, details like brand, model, variant, and year of purchasing. CredR caters to cities like Bangalore, Pune, Delhi NCR, Mumbai, Jaipur, Chittorgarh, Faridabad, Bhilwara, Gandhinagar, Noida, Gurgaon, Mysore, Pune, Thane, and Ahmedabad.</p>
                <p>Sell my bike with CredR by following three simple steps and get a host of immeasurable benefits; </p>
                <ul>
                  <li>Submit your bike details- Sell your bike by simply filling in all the information forms and get all the instant estimation of the price range for your two wheeler.</li>
                  <li>Get free Inspection- Once you have submitted all the details, a CredR executive will visit your location for a free inspection. You will thereafter get the final price for your bike.</li>
                  <li>Sell your bike with CredR- Once all the parameters are checked and verified, you’ll get payment within 48 hours in your bank account. CredR takes care of the RC transfer for free.</li>
                </ul>
                <p>The process of selling your bike is listed below;</p>
                <ol>
                  <li>Go to the CredR website/app.</li>
                  <li>Click on Sell your Bike page and submit your details OR Click on the WhatsApp icon and register your bike details.</li>
                  <li>Submit 4 images of your bike along with an image of the RC.</li>
                  <li>Your bike will be evaluated on critical parameters.</li>
                  <li>You’ll receive an immediate price quote for your bike.</li>
                  <li>Sell your bike to CredR with all legal paperwork and receive payment quickly.</li>
                </ol>
                <p>Sell my bike to CredR for the best price, no legal hassle, sellers safety assured, and quick payment. All done right with CredR!</p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={pannel === 'panel3'}
              onChange={() => togglePannel('panel3')}
              classes={{ root: 'faq-home' }}
              key={'panel3'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel3'}
              >
                CredR has showrooms in which cities?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>CredR is one of the most preferred omnichannel consumer brands entrusted to buying and selling used bikes. Being a pioneer in the largely unorganized industry, CredR has set up multiple showrooms spread across different cities to serve the customers from time to time. CredR caters to cities like Bangalore, Pune, Delhi NCR, Mumbai, Jaipur, Chittorgarh, Faridabad, Bhilwara, Gandhinagar, Noida, Gurgaon, Mysore, Pune, Thane, and Ahmedabad..</p>
               
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {/* <ExpansionPanel
              expanded={pannel === 'panel4'}
              onChange={() => togglePannel('panel4')}
              classes={{ root: 'faq-home' }}
              key={'panel4'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel4'}
              >
                How to buy a scooty from CredR?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>Buying a used scooty from CredR is easy. We have the coolest collection of second-hand two wheeler scooty- the star players in the motorcycle industry when it comes to comfort and mileage. Buying a second hand scooty provides an advantage to travel with ease in huge traffic areas. </p>
                <p>By visiting the online website of CredR you can witness the large range of scooters listed in order. Choose your location and set your budget to narrow down your buying options for used two wheeler. Check out the list of scooty available to fit in your budget and the maximum you can stretch to get a good scooty, CredR will help you out. The categories include Honda Activa, Honda Dio, Suzuki Access, Yamaha Ray, and TVS Jupiter.</p>
                <p>Fill in the details accurately and the CredR executive will get in touch with you shortly. With the doorstep delivery scheme, you can now enjoy a trial run for the second hand two wheeler scooty. A test drive can be booked by just paying Rs. 399. Once you finalize choosing the preferred used two wheeler- a CredR executive will guide you to process your buying parameters.</p>
              </ExpansionPanelDetails>
            </ExpansionPanel> */}
            <ExpansionPanel
              expanded={pannel === 'panel5'}
              onChange={() => togglePannel('panel5')}
              classes={{ root: 'faq-home' }}
              key={'panel5'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel5'}
              >
                How to buy a Scooty from CredR?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>By visiting the online website of CredR you can witness the large range of scooters listed in order. Choose your location and set your budget to narrow down your buying options for used two wheeler. Check out the list of used scooty available to fit in your budget. The top brands include Honda Activa, Honda Dio, Suzuki Access, Yamaha Ray, TVS pep+, TVS Jupiter and more.</p>
                <p>Fill in the details accurately, further a CredR executive will get in touch with you. With the doorstep delivery service, you can now enjoy a trial run for the second hand scooty. A test drive can be booked by just paying Rs. 399. Once you finalize choosing the preferred used two-wheeler, a CredR executive will guide you to process your buying parameters.</p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={pannel === 'panel6'}
              onChange={() => togglePannel('panel6')}
              classes={{ root: 'faq-home' }}
              key={'panel6'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel6'}
              >
                How does CredR’s doorstep service help?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>Transparency, Trust, and hassle-free service for the buyer/seller is our priority. CredR is one of the Most Preferred omnichannel consumer brands entrusted to buying and selling used bikes. We love to upgrade with the growing trend making everything customer-friendly. To ease the buying, selling and servicing of bikes, we have made the doorstep option available to all. The consumers can find a bike on the website and schedule a test drive from home.</p>
                <p>Amidst the pandemic, this doorstep feature has made buying and selling of second hand two-wheelers easy. You can even service your bike by getting in touch with the CredR executive for further process.</p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={pannel === 'panel7'}
              onChange={() => togglePannel('panel7')}
              classes={{ root: 'faq-home' }}
              key={'panel7'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel7'}
              >
               What type of offers and payment method is available?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>At CredR the customers have the privilege to unlock a wide range of deals while buying a used bike/scooty from CredR. Apart from the top class after-service you now can be assured of 6 months warranty, and 7-day buy the product. </p>
              <p>CredR offers festive discounts on the used bikes along with other regular offers (offers will be updated on website).  There are EMI plans made possible on the payment front once you buy a used bike.</p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={pannel === 'panel8'}
              onChange={() => togglePannel('panel8')}
              classes={{ root: 'faq-home' }}
              key={'panel8'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel8'}
              >
               Why should I buy a used bike from CredR?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
                <p>CredR brings to you the best collection of used bikes at your doorstep taking care of all the details related to it. Purchasing a used bike/ used scooty has many advantages;</p>
                <ul>
                  <li>Lower expense</li>
                  <li>New to the city, need a two-wheeler urgently</li>
                  <li>Lower premium rates</li>
                  <li>Perfect for daily use and long trips</li>
                </ul>
                <p>The age of the second hand two wheeler bike does not reduce its value and insurance premium. In an unorganized market, CredR has made customers satisfied with their service. The quality of the second hand bikes or scooty at CredR is premium. It goes through necessary refurbishment & 120+ checkpoints inspection done by auto experts. The used bikes are available at an affordable price. There are post buying benefits like 6 months warranty and 7 Day buy protection. </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={pannel === 'panel9'}
              onChange={() => togglePannel('panel9')}
              classes={{ root: 'faq-home' }}
              key={'panel9'}
            >
              <ExpansionPanelSummary
                expandIcon={<div className="faq-expand-arrow"><img className="arrow-red" src={ExpandArrow} alt="Expand" /></div>}
                classes={{ root: 'faq-question' }}
                id={'panel9'}
              >
                What are the advantages of buying a second hand bike?
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: 'faq-answer' }}>
              <p>The craze of riding a bike or a scooty is on the higher side. It saves a lot of time to travel. With this idea, CredR brings to you user-friendly second hand bikes at an affordable price. You can sell your bike or buy used bikes without any hassle. </p>
              <p>There are various reasons for choosing used bikes, all are listed below:</p>
              <ul>
                <li>Convenient, reliable and affordable.</li>
                <li>Multiple brands under one roof</li>
                <li>At CredR the bike/scooty conditions are ‘A’ graded ‘</li>
                <li>No hassle of legal work</li>
                <li>Doorstep Facility</li>
                <li>Bike service at your location at your preferred time</li>
                <li>Quick RC transfer rather than spending days for registration</li>
                
              </ul>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>
      </div>
    </div>
  )
}
