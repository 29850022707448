import React, { Fragment } from 'react'
import { FRANCHISE } from '../../../core/constants/pageConstatnts';
import { useSelector, useDispatch } from 'react-redux'
import { setSideBar } from '../../../redux/actions/sidebarAction'
import { Link } from 'react-router-dom'
import complaintsicon from '../../../assets/images/complaintsicon.svg'
import Badge from '@material-ui/core/Badge';
import { getBikes } from '../../../redux/actions/bikeListAction'
import { getCity } from '../../../core/services/storageService'
import { getUrlSanatizedString, debounce } from '../../../core/utility';
import './info.scss'

const InfoBar = ({ page, city }) => {

  const isOpen = useSelector(state => state.sidebar.isOpen)
  const dispatch = useDispatch()

  const toggleSideBar = () => {
    dispatch(setSideBar(!isOpen))
  }

  const getCityOnCondition = () => {
    if (city === 'select' || city === 1) {
      return true
    }
    else {
      return false
    }
  }

  const getServiceCond = () => {
    if(city === 'select' || city === 1) {
      return false
    }
    else {
      return true
    }
  }

  return (
    <Fragment>
      {
        page === FRANCHISE ?
          (
            <div className="franchiseInfo font-semibold">
              <span className="enquiry text-white">For Franchise Enquiry :</span>
              <div className="contactInfo">
                <i className="phoneIcon float-left"></i>
                888 488 5455
                  </div>
              <div className="franchiseMail">
                <a href="mailto:cs@credr.com">
                  <i className="mailIcon"></i>
                </a>
                <a href="mailto:cs@credr.com" className="franchiseEmails">cs@credr.com</a>
              </div>
            </div>
          ) :
          (
            <>
              <div className="csDetails csDetails-container">
                {/* <div className="whatsApp">
                <a href="https://wa.me/917353333200?text=Hello%20CredR" aria-label="Support Whatsapp" target="_blank" rel="noopener noreferrer">
                  <i className="Icon float-left"></i>
                </a>
                <a href="https://wa.me/917353333200?text=Hello%20CredR" target="_blank" rel="noopener noreferrer" className="franchiseEmails">73533 33200</a>
              </div>
              <div className="csMail">
                <a href="mailto:cs@credr.com" aria-label="Support Mail">
                  <i className="mailIcon float-left"></i>
                </a>
                <a href="mailto:cs@credr.com" className="franchiseEmails">cs@credr.com</a>
              </div> */}
                {/* <span className="enquiry text-white">For Customer Support:</span> */}
                {/* <div> */}
                  <Link className="csDetails font7 font-semibold text-white mr-2" to="/sellbikes" style={{ color: 'red' }}>Sell</Link>
                  <Link className="csDetails font7 font-semibold text-white mr-2" to={getCity() ? `/all-used-bikes-in-${getUrlSanatizedString(getCity().cityName)}` : `/all-used-bikes` } style={{ color: 'white' }}>Buy</Link>
                  {/* {
                    getServiceCond() &&
                    <Link className="csDetails font10 font-semibold text-white" to="/door-step-service" style={{ color: 'Yellow' }}>Service</Link>
                  } */}
                  {/* {
                    // getServiceCond() &&
                    <Link className="csDetails font7 font-semibold text-white mr-2" to="/door-step-service" style={{ color: 'Yellow' }}>
                      <Badge className='buyAtHome' color="secondary" badgeContent='New' showZero>
                        Service
                      </Badge>
                      </Link>
                  } */}
                  {/* {
                    getCityOnCondition() &&
                    <Link className="csDetails font10 font-semibold text-white" style={{ color: 'Yellow' }} to={ getCity() ? `/all-used-bikes-buy-at-home-in-${getUrlSanatizedString(getCity().cityName)}?buyathome` : `/all-used-bikes-buy-at-home?buyathome`} >
                      <Badge className='buyAtHome' color="secondary" badgeContent='New' showZero>
                        Buy At Home
                    </Badge>
                    </Link>
                  } */}
                    {/* <Link className="csDetails font7 font-semibold text-white mr-2" to='/franchise' style={{ color: 'pink' }}> 
                  <Badge className='buyAtHome' color="secondary" badgeContent='New' showZero>
                  Franchise
                  </Badge>
                  </Link> */}
                  <Link className="csDetails font7 font-semibold text-white mr-2" to='/new-bikes' style={{ color: 'pink' }}> 
                  {/* <Badge className='buyAtHome' color="secondary" badgeContent='New' showZero> */}
                  New Bikes
                  {/* </Badge> */}
                  </Link>
                  <Link className="csDetails font10 font-semibold text-white mr-2" to='/contact-us'>
                    <img src={complaintsicon} className="contactIcon" alt="contact" />
                  </Link>
                  {/* <Link className="csDetails font10 font-semibold text-white" to='/franchise'>Own a Franchise</Link> */}
              </div>
              <Link to='/contact-us'>
                <img src={complaintsicon} className="contactIconMobile float-right mr-2" alt="contact" />
              </Link>
            </>
          )
      }
      <img   src="https://dqbkg06i6c7gd.cloudfront.net/assets/images/hamburger-icon.svg" id="sidebarCollapse" className="hamburgerIcon float-right pr-0" onClick={toggleSideBar} alt="menu"/>
    </Fragment>
  )
}

export default InfoBar